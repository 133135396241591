.app.dark {
  background-color: #323238;
  color: rgb(156, 156, 156);

  @media (prefers-color-scheme: dark) {
    body {
      background-color: var(--background-dark);
      color: var(--text-dark);
    }
  }

  .sidebar {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 1px 1px 7px 0px #2a2a2a;
  }

  .cardKpi {
    background-color: #1f1f23;
  }

  .accordianSum {
    background-color: #1c1c1f;
    color: #fff;
  }

  .home {
    background-color: #323238;
  }

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }
  .image-dark {
    content: url("../assets/images/logo-light.png");
  }

  .table {
    background-color: #323238;

    .tableCell {
      color: gray;
    }
  }

  .company {
    color: white;
  }

  .title {
    color: white;
  }

  .image-logo {
    background-color: #26262b;
  }

  .topbar {
    // background-color: #26262b;
  }

  .nav-link {
    color: #999;
  }

  .nav-link.active {
    color: #999;
  }

  .widget,
  .chart,
  .featured,
  .listContainer {
    background-color: #26262b;
  }

  .navbar {
    color: #fff;
    border-color: #333;
    background-color: #323238;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #1f1f23;
    border-color: #333;
    color: #fff;

    .top {
      .logo {
        color: #999;
      }
    }
    hr {
      border-color: rgb(210, 210, 210);
    }

    ul {
      li {
        background-color: #323238;

        &:hover {
          background-color: #333;
        }

        .icon {
          color: rgb(195, 195, 195);
        }
      }
    }

    .accordian-icon {
      color: #999;
    }

    .item-active {
      background-color: #ec5210;
      -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.47);
      box-shadow: 0px 0px 0px 0px #2a2a2a;

      .icon {
        color: antiquewhite;
      }
    }
  }

  .navbarDash {
    background-color: #1f1f23;
  }

  .account-menu {
    background-color: #1f1f23;
  }

  .datatable {
    .datagrid {
      color: gray;
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }
  }

  input {
    background-color: transparent;
  }

  .detail-label {
    color: #ffffff;
  }

  .detail-block {
    background-color: #1c1c1f;
    border: 1px solid #383838;
    color: #fff;
    border-radius: 8px;
  }
  .select-object {
    background-color: #888;
  }

  .project-heading {
    color: #fff;
  }

  .project-ticket {
    color: #888;
  }

  .project-type {
    color: #ec5210;
  }

  .formControl {
    background-color: #3b3b42;
    color: #fff;
    border: 1px solid #1f1f23;
  }

  .autocomplete-object {
    min-width: 300px;
    background: #3b3b42 0% 0% no-repeat padding-box;
    border: 1px solid #1f1f23;
    border-radius: 5px;
    margin-right: 5px;
    // margin-bottom: 15px;  ronaldg 329363 - to align with the light theme
    font-size: 14px;
    color: #fff;
  }
}
